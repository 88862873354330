import axios from '@/utils/request';

/*********************** 心愿工单 ******************/
// 查询问题列表
export function queryQuestionList(data) {
  return axios.get(`desire/list`, {
    params: data,
  });
}
// 发布问题
export function addQuestion(data) {
  return axios.post(`desire/add`, data);
}
// 查询问题评论
export function queryQuestCommont(data) {
  return axios.get(`desire/cmt/list`, {
    params: data,
  });
}
// 采纳
export function adoption(data) {
  return axios.post(`desire/adoption`, data);
}
// 查询问题详情
export function queryQuestInfo(data) {
  return axios.get(`desire/info`, {
    params: data,
  });
}

/*********************** 粉丝列表 ******************/
// 查询粉丝列表
export function queryFansList(data) {
  return axios.get(`follow/fans/list`, {
    params: data,
  });
}

// 查询关注列表
export function queryFollowList(data) {
  return axios.get(`follow/list`, {
    params: data,
  });
}

//评论回复
export function commentSend(data) {
  return axios.post(`comment/sendV2`, data);
}

/*********************** 大v认证 ******************/
// 大v认证
export function officialcert(data) {
  return axios.post(`mine/officialcert/submit`, data);
}
//查询认证结果
export function queryCialcertResult(data) {
  return axios.get(`mine/officialcert/get`, {
    params: data,
  });
}

/*********************** 我的收藏 ******************/
// 查询收藏列表
export function queryCollecList(data) {
  return axios.get(`mine/collect/infoList`, {
    params: data,
  });
}

/*********************** 精品应用/官方社群 ******************/
export function officialList(data) {
  return axios.get(`official/list`, {
    params: data,
  });
}

/*********************** 账单明细 ******************/
export function billDetail(data) {
  return axios.get(`mine/zqzhangdan`, {
    params: data,
  });
}

/*********************** 流水明细 ******************/
export function flowDetail(data) {
  return axios.get(`mine/bills`, {
    params: data,
  });
}

/*********************** 推广记录 ******************/
export function promotionRecord(data) {
  return axios.post(`userinvite/userlist`, data);
}

/*********************** 会员中心 ******************/
export function queryVipList(data) {
  return axios.get(`vip/product`, {
    params: data,
  });
}
// 获取新手卡时间
export function queryTiroCountdown() {
  return axios.get(`vip/tiroCountdown`);
}

//兌換碼
export function exchageCode(data) {
  return axios.post(`code/exchange`, data);
}

//兑换码列表
export function exchangeList(data) {
  return axios.get(`code/exchangeRecord`, data);
}

/*********************** 我的购买 ******************/
export function queryBuyList(data) {
  return axios.get(`mine/buyVid`, {
    params: data,
  });
}

/*********************** 会员中心 ******************/
// 金币兑换
export function goldExchange(data) {
  return axios.post(`product/buy`, data);
}
// 充值
export function getPay(data) {
  return axios.post(`mine/topay`, data);
}
// 查询金幣列表
export function queryCurrencys(data) {
  return axios.get(`mine/currencys`, {
    params: data,
  });
}

/*********************** 账号凭证 ******************/
// 查询账号凭证
export function queryCertificate(data) {
  return axios.get(`mine/certificate/qr`, {
    params: data,
  });
}

/*********************** 提现 ******************/
// 查询提现配置
export function queryWithdrawCfg(data) {
  return axios.get(`withdraw/cfg`, {
    params: data,
  });
}
// 查询提现明细
export function queryWithdrawDetails(data) {
  return axios.get(`withdraw/order`, {
    params: data,
  });
}
// 提现
export function withdraw(data) {
  return axios.post(`withdraw`, data);
}
// 检验银行卡号
export function checkBankCard(data) {
  return axios.get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json`, {
    params: data,
  });
}

/*********************** 用户主页 ******************/
// 查询打赏记录
export function queryRewardLog(data) {
  return axios.get(`mine/reward/log`, {
    params: data,
  });
}

export function publisherTop(data) {
  return axios.post(`vid/work/top`, data);
}

// 获取
export function querySessionId(data) {
  return axios.get('msg/session/get', {
    params: data,
  });
}

/*********************** 动态 ******************/
// 查询动态列表
export function queryDynamicList(data) {
  return axios.get(`msg/dynamic/list`, {
    params: data,
  });
}

/*********************** 创作中心 ******************/
//创作视频
export function getPublish(data) {
  return axios.get('publish/details', {
    params: data,
  });
}

// 周榜详情
export function getLeaderboard(data) {
  return axios.get('publish/leaderboard', {
    params: data,
  });
}

/*********************** 任务中心 ******************/
//查询任务列表
export function queryTask(data) {
  return axios.get('task', {
    params: data,
  });
}

// 签到
export function taskSing(data) {
  return axios.post('/task/sign', data);
}

/*********************** 收益明细 ******************/
//查询收益明细
export function queryIncomeDetails(data) {
  return axios.get('mine/iIncomes', {
    params: data,
  });
}

/*********************** 收藏 ******************/
//收藏里表
export function queryfavorites(data) {
  return axios.get('mine/collect/infoList', {
    params: data,
  });
}
