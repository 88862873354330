<template>
  <div class="officialCommunity">
    <Header title="官方社群" />
    <div class="main">
      <div class="container" v-if="list.length">
        <div class="cellItem" @click="jump(item.officialUrl)" v-for="item in list" :key="item.officialImg">
          <ImgDecypt class="bgImg" :src="item.officialImg" />
        </div>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import { officialList } from '@/api/mine';
import ImgDecypt from '@/components/ImgDecypt';
import NoData from '@/components/NoData';
import { Toast } from 'vant';
export default {
  name: 'OfficialCommunity',
  components: {
    Header,
    ImgDecypt,
    NoData,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let req = { type: 2 }; // 精品应用-1 官方社群-2
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(officialList, req);
        console.log(res);
        if (res && res.code == 200) {
          this.list = res.data ? res.data : [];
          this.$store.commit('app/SET_LOADING', false);
        } else {
          Toast(res.tip || '请求失败');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('请求失败');
      }
    },
    // 跳转
    jump(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.img_Decypt .laz_com) {
  background-color: unset;
}
.officialCommunity {
  height: 100%;
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    .container {
      .cellItem {
        height: 91px;
        width: 343px;
        position: relative;
        margin-bottom: 10px;
        .bgImg {
          height: 91px;
          width: 343px;
        }
      }
    }
  }
}
</style>
